import React, { useState } from 'react';
import styles from './FAQ.module.css';

const FAQ = () => {
    const faqData = [
        {
            question: "Jak wygląda zarezerwowanie oprawy muzycznej krok po kroku?",
            answer: "W celu rezerwacji podpisujemy umowę i wpłacamy zaliczkę w wysokości 50 zł. Miesiąc przed ślubem ustalamy szczegóły."
        },
        {
            question: "Czy muszę porozmawiać z organistą, jeśli chciałabym, żeby Pani śpiewała na ślubie?",
            answer: "Tak, to jest bardzo ważne. Najlepiej na samym początku planowania porozmawiać z księdzem i organistą."
        },
        {
            question: "Ile utworów śpiewa Pani podczas mszy?",
            answer: "Zazwyczaj śpiewam 5/6 utworów."
        },
        {
            question: "W jakich momentach mszy Pani śpiewa?",
            answer: "Śpiewam na czas zbierania się gości, chwilę przed ślubem. Na wejście, na ofiarowanie, na komunię, na dziękczynienie i na zakończenie mszy."
        },
        {   question: "Czy jest możliwość oprawy całej mszy świętej?",
            answer: "Jak najbardziej, ale tylko w przypadku, gdy jestem ze swoim muzykiem. Gramy wtedy utwory oraz części stałe mszy i organista nie jest nam potrzebny."
        },
        {   question: "Czym różni się śpiew do akompaniamentu organisty od śpiewu do akompaniamentu Pani pianisty?",
            answer: "Po pierwsze przy akompaniamencie organisty nie zawsze mogę zaśpiewać wszystkie wybrane przez Państwa utwory (organista może czegoś nie znać, albo nie pasuje to do akompaniamentu organowego) Po drugie z organistą mamy mało czasu na zrobienie próby. Zazwyczaj robimy to w dniu ślubu chwilę przed mszą, a ze swoim muzykiem mam już wcześniej opracowany cały materiał i próba nie jest nam potrzebna. Po trzecie, jeśli gram ze swoim pianistą, to używam swojego nagłośnienia, a przy akompaniamencie organisty śpiewam do mikrofonu, który jest w kościele i nie mam wpływu na to, jakie jest tam nagłośnienie.",
        },
        {   question: "Czy mogę dobrać dodatkowy instrument albo wokal?",
            answer: "Jak najbardziej! Wszystkie instrumenty (pianino, gitara, skrzypce, wiolonczela, saksofon, trąbka harfa) dobieramy według uznania do każdego z wariantu. Istnieje również możliwość dobrania wokalu damskiego lub męskiego. Jeżeli potrzebują Państwo porady w tym temacie, zawsze chętnie pomogę.",
        },
        {   question: "Czy zmienia się koszt oprawy muzycznej przy ślubach w odległych miejscach?",
            answer: "Przy ślubach oddalonych od Warszawy o ponad 100 km, stawka ulega zmianie i jest ustalana indywidualnie.",
        }
    ];

    const [openQuestion, setOpenQuestion] = useState(null);

    const toggleAnswer = (index) => {
        if (openQuestion === index) {
            setOpenQuestion(null);
        } else {
            setOpenQuestion(index);
        }
    };

    return (
        <div className={styles.faqContainer}>
            <h1 className={styles.faqTitle}>Najczęściej zadawane pytania</h1>
            <div className={styles.faqList}>
                {faqData.map((item, index) => (
                    <div key={index} className={styles.faqItem}>
                        <div className={styles.faqQuestion} onClick={() => toggleAnswer(index)}>
                            {item.question}
                            <span className={openQuestion === index ? styles.arrowUp : styles.arrowDown}></span>
                        </div>
                        {openQuestion === index && (
                            <div className={styles.faqAnswer}>{item.answer}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;

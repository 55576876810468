import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import weseleZklasa from './LOGO1.png';

const Footer = () => {

    const email = 'nataliaa.watkowska@gmail.com'; // Przeniesiona tutaj
    const phoneNumber = '+48663339783';


    return (
        <div className={styles.footerContainer}>
            <div className={styles.columnsContainer}>
                {/* Kolumna "Kontakt" */}
                <div className={styles.column}>
                    <h1 className={styles.columnHeader}>Kontakt:</h1>
                    <div className={styles.contactInfo}>
                    <a href={`tel:${phoneNumber}`}>
                        <div className={styles.contactItem}>
                            <FontAwesomeIcon icon={faPhone} size="2x" className={styles.icon} />
                            <p>663 339 783</p>
                        </div>
                        </a>
                        <a href={`mailto:${email}`}>
                        <div className={styles.contactItem}>
                            
                                <FontAwesomeIcon icon={faEnvelope} size="2x" className={styles.icon} />
                                <p>nataliaa.watkowska@gmail.com</p>
                            
                        </div>
                        </a>
                        <Link to="https://www.weselezklasa.pl/ogloszenia-weselne/oprawa-wokalna-slubu,34077/" target="_blank" rel="noopener noreferrer">
                        <div className={styles.contactItem}>
                                <img src={weseleZklasa} alt="logo portalu wesele z klasa" className={styles.weseleZklasa} />
                                <p>&nbsp;&nbsp;Wesele z klasą</p>
                        </div>
                        </Link>
                    </div>
                </div>

                {/* Kolumna "Śledź mnie" */}
                <div className={styles.column}>
                    <h1 className={styles.columnHeader}>Śledź mnie:</h1>
                    <div className={styles.iconContainer}>
                        <Link to="https://www.facebook.com/nataliaa.watkowska" target="_blank" rel="noopener noreferrer">
                            <div className={styles.iconWithText}>
                                <FontAwesomeIcon icon={faFacebookF} size="2x" color="#f6ece8" className={styles.socialIcon} />
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Facebook</p>
                            </div>
                        </Link>
                        <Link to="https://www.instagram.com/natalia_watkowska/?fbclid=IwAR1OF4Njgjrhnok-18FK_wpiwZyBwVBaoKjpECz_K8I1yMWmhbdVFzVHwaI" target="_blank" rel="noopener noreferrer">
                            <div className={styles.iconWithText}>
                                <FontAwesomeIcon icon={faInstagram} size="2x" color="#f6ece8" className={styles.socialIcon} />
                                <p>&nbsp;&nbsp;Instagram</p>
                            </div>
                        </Link>
                        <Link to="https://www.youtube.com/channel/UCweZaNh6Tx8p7IEfNKxPTcA" target="_blank" rel="noopener noreferrer">
                            <div className={styles.iconWithText}>
                                <FontAwesomeIcon icon={faYoutube} size="2x" color="#f6ece8" className={styles.socialIcon} />
                                <p>YouTube</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Kreska przerwy */}
            <hr className={styles.divider} />

            {/* Napis firmy */}
            <div className={styles.siteAuthor}>
            <Link to="https://visualfusion.pl"> <p>© 2023 VisualFusion.pl</p></Link>
            </div>
        </div>
    );
}

export default Footer;

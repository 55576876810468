import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FAQ from './FAQ/FAQ';
import Footer from './Footer/Footer';
import Navigation from './Navigation/Navigation';
import MainPage from './MainPage/MainPage';
import Services from './Usługi/Uslugi';

function App() {
  return (
    <BrowserRouter>
      <>
        <Navigation />
        <Routes>
          <Route path='/' element={<MainPage/>}/>
          <Route path="FAQ" element={<FAQ />} />
          <Route path="/oferta" element={<Services/>} />
        </Routes>
        <Footer />
      </>
    </BrowserRouter>
  );
}

export default App;

// YoutubeModal.jsx
import React from 'react';
import styles from './YoutubeModal.module.css'; // Stylizacja modalu

const YoutubeModal = ({ isOpen, onClose, src }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <iframe
                    width="560"
                    height="315"
                    src={src}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
                <button className={styles.closeButton} onClick={onClose}>
                    X
                </button>
            </div>
        </div>
    );
}

export default YoutubeModal;

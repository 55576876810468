import React, { useState, useEffect } from 'react';
import styles from './Records.module.css';
import YoutubeModal from '../YoutubeModal/YoutubeModal';
import piano from './piano_1.webp';
import ScrollReveal from 'scrollreveal';

const Records = () => {
    const [showModal, setShowModal] = useState(false);
    const [youtubeSrc, setYoutubeSrc] = useState('');

    useEffect(() => {
        const sr = ScrollReveal();
    
        sr.reveal(`.${styles.imageColumn}`, {
          duration: 2800,
          scale: 0.6,
          distance: '300px',
          origin: 'left',
          reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
        });
      }, []);

    const songData = [
        { title: "Chcemy Ciebie wielbić", youtubeLink: "https://www.youtube.com/embed/bzgAx9k4xYw?si=jhENZahIkXR1vXMZ" },
        { title: "Niepojęty, niezmierzony", youtubeLink: "https://www.youtube.com/embed/KuCpR9_AnBM?si=EoMKgmQHY-J9J5A1" },
        { title: "Panie proszę przyjdź", youtubeLink: "https://www.youtube.com/embed/U2lqjD9b48M?si=4kHLWK7n9IKWiKMf" },
        { title: "Twoja miłość jak ciepły deszcz", youtubeLink: "https://www.youtube.com/embed/WASK00EkC0M?si=BKL-7f9NlKCLvKbs" },
        { title: "Niechaj miłość Twa", youtubeLink: "https://www.youtube.com/embed/A_0UoPrW-l4?si=1YCJePG5ZS54LCod" },
        { title: "Jakie piękne jest imię Twe", youtubeLink: "https://www.youtube.com/embed/LBVUbk-NCBY?si=P_SoAZxJxJFckBk1" },
        { title: "Jak przedziwna Panie Twoja miłość jest", youtubeLink: "https://www.youtube.com/embed/rueLV76A5XE?si=Pe8AiKd8ZR-ck8-O" },
        { title: "Cherish the day", youtubeLink: "https://www.youtube.com/embed/GKrYJuFkNj8?si=bwAF7JOhsN5JqGfR" },
        { title: "Koncert muzyki rozrywkowej", youtubeLink: "https://www.youtube.com/embed/-_eWjs37kLc?si=Xu6so37Z4DZDVfij" },
        { title: "Ty tylko mnie poprowadź", youtubeLink: "https://www.youtube.com/embed/LtEw-r2PMY0?si=Wz35rsLpxK0gGsMJ" },
        { title: "Schowaj mnie", youtubeLink: "https://www.youtube.com/embed/Fq4IS1mZ3O0?si=xlID8EdYbNgsAvLj" },
        { title: "W cieniu Twoich rąk", youtubeLink: "https://www.youtube.com/embed/hpCvK6Qbzdc?si=UVY8RrNMkPMRR0v5" },

    ];

    const handleOpenModal = (youtubeLink) => {
        setShowModal(true);
        setYoutubeSrc(youtubeLink);
    };

    return (
        <div id="recordings" className={styles.recordsContainer}>
            <div className={styles.leftContent}>
        <div className={styles.content}>
            <div className={styles.title}>
            <h1>Nagrania</h1>
            </div>
                <div className={styles.buttonsColumn}>
                    {songData.map((song, index) => (
                        <div
                            key={index}
                            className={styles.button}
                            onClick={() => handleOpenModal(song.youtubeLink)}
                        >
                            {song.title}
                        </div>
                    ))}
                </div>
                
                </div>
                <div className={styles.imageColumn}>
                    <img src={piano} alt="ja przy fortepianie" className={styles.pianoImage} />
                </div>
            </div>
            <YoutubeModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                src={youtubeSrc}
            />
        </div>
    );
}

export default Records;

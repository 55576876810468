// EnglishServices.js

import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import styles from './Uslugi.module.css';
import image1 from './image1.jpg';
import image2 from './image2.jpg';
// import image3 from './image3.jpg';
import image4 from './image4.jpg';
import image5 from './image5.jpg';
import image7 from './image6.jpg';

const Services = () => {

  const { id_uslugi } = useParams();

  useEffect(() => {
    // Tutaj możesz wykorzystać id_uslugi do wyświetlenia odpowiednich informacji
  }, [id_uslugi]);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(`.${styles.text}, .${styles.title}, .${styles.package}, .${styles.list}, .${styles.additional}`, {
      duration: 1000,
      scale: 1,
      distance: '300px',
      origin: 'left',
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });
})

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.columnLeft}>
          <h1 id="oprawa" className={styles.title}>Oprawa Muzyczna Ślubów</h1>
          <p className={styles.text}>
            Jeżeli marzysz o pięknej, delikatnej i wzruszającej oprawie muzycznej, jestem tutaj, aby spełnić Twoje marzenia. Posiadam lata doświadczenia muzycznego, a śpiew podczas ślubów to moja wielka pasja. Oferuję usługi muzyczne na ślubach kościelnych, cywilnych oraz plenerowych. Proponuję trzy pakiety:
          </p>
          <p className={styles.package}>PAKIET SREBRNY</p>
          <ul className={styles.list}>
            <li>Wokal + podkłady instrumentalne + nagłośnienie</li>
            <li>Do 5 utworów</li>
            <li>Konsultacje dotyczące wyboru repertuaru</li>
          </ul>
          <p className={styles.package}>PAKIET ZŁOTY</p>
          <ul className={styles.list}>
            <li>Akompaniament pianisty</li>
            <li>Do 6 utworów</li>
            <li>Unikalne aranżacje utworów</li>
            <li>Przygotowanie instrumentu i nagłośnienia</li>
            <li>Konsultacje dotyczące wyboru repertuaru</li>
          </ul>
          <p className={styles.package}>PAKIET BRYLANTOWY</p>
          <ul className={styles.list}>
            <li>Akompaniament pianisty i skrzypaczki</li>
            <li>Do 6 utworów</li>
            <li>Unikalne aranżacje utworów</li>
            <li>Przygotowanie instrumentu i nagłośnienia</li>
            <li>Konsultacje dotyczące wyboru repertuaru</li>
          </ul>
          <p className={styles.package}>DODATKOWE USŁUGI:</p>
          <ul className={styles.list}>
            <li>Oprawa całej Mszy Świętej (utwory i wszystkie części Mszy)</li>
            <li>Występ na skrzypcach podczas składania życzeń</li>
            <li>Wykonanie utworów podczas składania życzeń (dostępne tylko w pakietach Złotym i Brylantowym, dla ślubów cywilnych i plenerowych)</li>
            <li>Koncert podczas obiadu weselnego </li>
          </ul>
        </div>
        <div className={styles.columnRightImg}>
          <img src={image1} alt="Muzyka Ślubna" className={styles.imageRight} />
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.row}>
        <div className={styles.columnLeftImg}>
          <img src={image7} alt="Chór" className={styles.imageLeft} />
        </div>
        <div className={styles.columnRight}>
          <h1 id="chrzciny" className={styles.title}>Oprawa Muzyczna Podczas Chrzcin i Komunii</h1>
          <p className={styles.text}>
            Niezależnie od tego, czy planujesz chrzest czy komunię, mogę dostarczyć piękną oprawę muzyczną, aby ten dzień był jeszcze bardziej wyjątkowy.
          </p>
          <p className={styles.additional}>Dodatkowe usługi:</p>
          <ul className={styles.list}>
            <li>Oprawa całej Mszy Świętej (utwory i wszystkie części Mszy)</li>
            <li>Występ na skrzypcach podczas składania życzeń</li>
            <li>Wykonanie utworów podczas składania życzeń (dostępne w pakietach Złotym i Brylantowym)</li>
            <li>Koncert podczas obiadu</li>
          </ul>
          <h1 id="obiad" className={styles.title}>Koncert Podczas Obiadu Weselnego</h1>
          <p className={styles.text}>
            Myślisz się nad uroczystym obiadem zamiast tradycyjnego wesela? Chcesz, żeby wybrzmiewała wtedy muzyka na żywo? To doskonały pomysł! Oferuję 45-minutowy koncert wokalny, z udziałem pianina i/lub skrzypiec. W moim repertuarze znajdują się polskie i zagraniczne przeboje muzyki rozrywkowej. Mogę także zaoferować wykonanie utworów instrumentalnych.
          </p>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.row}>
        <div className={styles.columnLeft}>
        <h1 id="kamerlane"  className={styles.title}>Koncerty Kameralne</h1>
          <p className={styles.text}>
            Koncerty okazjonalne (np. Walentynki, święta, jubileusze) dla firm, instytucji kulturalnych oraz osób prywatnych. Występuję z pianistą i jeśli to życzenie, dodatkowym skrzypkiem. Czas trwania koncertu i repertuar ustalane są indywidualnie.
          </p>
          <h1 id="animacje" className={styles.title}>Koncerty z Animacjami dla Dzieci</h1>
          <p className={styles.text}>
            Koncerty dla żłobków, przedszkoli, instytucji kulturalnych oraz osób prywatnych. 
            <br></br>W programie piosenki dziecięce, bajkowe, krótkie animacje i zabawy z wykorzystaniem różnych rekwizytów. Śpiewam wtedy do akompaniamentu pianisty. Czas trwania koncertu wynosi 45-60 minut.
          </p>
        </div>
        <div className={styles.columnRightImg}>
          <img src={image2} alt="Koncert Obiadowy" className={styles.imageRight} />
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.row}>
        <div className={styles.columnLeftImg}>
          <img src={image5} alt="Koncert Kameralny" className={styles.imageLeft} />
        </div>
        <div className={styles.columnRight}>
          <h1 id="gordon" className={styles.title}>Koncerty Gordonowskie</h1>
          <p className={styles.text}>
            Koncerty dla rodzin z małymi dziećmi od 3 miesiąca do 3 roku życia. Realizowane są w oparciu o teorię uczenia się muzyki wg E.E. Gordona. W programie melodie w różnych skalach oraz rytmiczanki o różnym metrum. Wszystko otoczone pięknym brzmieniem instrumentów (pianino, skrzypce, wiolonczela) oraz kolorowych rekwizytów.
          </p>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.row}>
        <div className={styles.columnLeft}>
          <h1 id="zajecia" className={styles.title}>Zajęcia Umuzykalniające dla Dzieci</h1>
          <p className={styles.text}>
            <strong>GORDONKI</strong><br />
            To zajęcia umuzykalniające dla dzieci od 3 miesiąca do 3 roku życia. Realizowane są w oparciu o teorię uczenia się muzyki wg E.E. Gordona. Głównym celem zajęć jest śpiewanie połączone z ruchem. Podczas zabawy prezentowane są melodie w różnych skalach oraz rytmiczanki o różnym metrum, a wszystko to w towarzystwie atrakcyjnych rekwizytów.
          </p>
          <p className={styles.text}>
            <strong>ZAJĘCIA WOKALNO-UMUZYKALNIAJĄCE</strong><br />
            To zajęcia przeznaczone dla dzieci w wieku 6-12 lat. W programie znajdują się ćwiczenia wokalne, ćwiczenia dykcyjne, ćwiczenia oddechowe, praca nad piosenką oraz praca z mikrofonem. Wszystko odbywa się w miłej, zabawnej atmosferze. Dzięki takim zajęciom dzieci mogą bardziej świadomie zanurzyć się w świecie muzyki.
          </p>
        </div>
        <div className={styles.columnRightImg}>
          <img src={image4} alt="Zajęcia Umuzykalniające" className={styles.imageRight} />
        </div>
      </div>
    </div>
  );
}

export default Services;

// Offer.js
import React, {useEffect} from "react";
import ScrollReveal from 'scrollreveal';
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./Offer.module.css";
import ptak from "./ptak.png";
import talerz from "./talerz.png";
import slub from "./obraczki.png";
import nuty from "./nuty.png";
import mikrofon from "./mikrofon.png";
import chlopiec from "./chlopiec.png";
import chlopiecbeben from "./chlopiecbeben.png";
import recordsimage from "./recordsimage.webp";

const Offer = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const handleOfferLinkClick = (elementId) => {
    // Jeśli jesteś na stronie FAQ, przewiń do sekcji "reviews"
    if (location.pathname === '/' ){
      navigate('/oferta');
      setTimeout(() => {
        scrollToElement(elementId);
      }, 10); // Przewiń do "reviews" po opóźnieniu
    }
  }

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ offset: -50, behavior: 'smooth', block: 'start'});
    }
  };

  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal(`.${styles.box}`, {
      duration: 2800,
      scale: 0.6,
      distance: '300px',
      origin: 'bottom',
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });
  }, []);

  const OfferName = [
    {
      id: 1,
      name: "Oprawa muzyczna ślubów",
      icon: slub,
      link: "oprawa",
      alt: "obraczki"
    },
    {
      id: 2,
      name: "Oprawa muzyczna chrzcin, komunii",
      icon: ptak,
      link: "chrzciny",
      alt: "golebie"
    },
    {
      id: 3,
      name: "Koncert podczas obiadu weselnego",
      icon: talerz,
      link: "obiad",
      alt: "talerze"
    },
    {
      id: 4,
      name: "Koncerty kameralne",
      icon: nuty,
      link: "kameralne",
      alt: "nuty"
    },
    {
      id: 5,
      name: "Koncerty z animacjami dla dzieci",
      icon: mikrofon,
      link: "animacja",
      alt: "mikrofon"
    },
    {
      id: 6,
      name: "Koncerty gordonowskie",
      icon: chlopiec,
      link: "gordon",
      alt: "chlopiec"
    },

    {
      id: 7,
    },
    {
      id: 8,
      name: "Zajęcia umuzykalniające dla dzieci",
      icon: chlopiecbeben,
      link: "zajecia",
      alt: "obraczki"
    },
  ];

  return (
    <>
      <div id="services" className={styles.offerTitle}>
        <h1>Oferta</h1>
      </div>
      <div className={styles.offerContainer}>
        <div className={styles.specialImage}>
          <img src={recordsimage} alt="Zdjęcie specjalne" />
        </div>
        <div className={styles.whiteCircle}>
          {OfferName.map((e) => (
            <div
              key={e.id}
              className={`${styles.box} ${e.id === 7 ? styles.specialBackground : ""}`}
            >
              {e.id !== 7 && <p>czytaj więcej</p>}
              <div className={styles.imgwrapper}>
              <Link to="oferta" onClick={() => { handleOfferLinkClick(e.link) }}>
                <img src={e.icon} alt={e.name} className={`${styles.icon2} `} />
                </Link>
                <h4 className={styles.h2_row}>{e.name}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Offer;

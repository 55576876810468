import React from 'react';
import AboutMe from '../AboutMe/AboutMe';
import Contact from '../Contact/Contact';
import Hero from '../Hero/Hero';
import Offer from '../Offer/Offer';
import Records from '../Records/Records';
import Opinions from '../Reviews/Reviews.jsx';


const MainPage = () => {
    return (
    <>
        <Hero />
        <AboutMe />
        <Offer />
        <Records />
        <Opinions />
        <Contact />
    </>
    )
}

export default MainPage
import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import styles from "./Contact.module.css";
import desk from "./ContactImage.jpg";
import ScrollReveal from "scrollreveal";

const Contact = () => {
  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal(`.${styles.contactHeader}`, {
      duration: 2700,
      scale: 0.8,
      distance: "500px",
      origin: "left",
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });

    sr.reveal(`.${styles.contactImage}`, {
      duration: 3000,
      scale: 0.8,
      distance: "500px",
      origin: "left",
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_jayrnem",
        "template_c8nl7ig",
        // maile idą z adres: natalia.formularz@gmail.com haslo: moje standardowe .. 
        {
          from_name: name,
          from_email: email,
          message: message,
        },
        "7eTIxZGRuLahAgznd"
      )
      .then((response) => {
        console.log("E-mail wysłany:", response);
        setIsSubmitted(true); // Ustaw stan na true po wysłaniu
      })
      .catch((error) => {
        console.error("Błąd podczas wysyłania e-maila:", error);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div id="contact" className={styles.contactContainer}>
      <div className={styles.contactImage}>
        <img src={desk} alt="ja przy biurku" className={styles.contactImage} />
      </div>
      <div className={styles.contactInfo}>
        <h1 className={styles.contactHeader}>Skontaktuj się ze mną</h1>
        {isSubmitted ? (
          <p className={styles.sendMail}>
            Dziękuję za przesłanie wiadomości. Odpowiemy najszybciej, jak to
            możliwe!
          </p>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              className={styles.inputField}
              type="text"
              placeholder="Imię i nazwisko:"
              value={name}
              onChange={handleNameChange}
              id="textInput" // Dodaj id dla tego pola
              name="name" // Dodaj name dla tego pola
              autoComplete="name" 
            />
            <input
              className={styles.inputField}
              type="email" 
              placeholder="Adres e-mail:"
              value={email}
              onChange={handleEmailChange}
              id="email" // Dodaj id dla tego pola
              name="user_email" // Dodaj name dla tego pola
              autoComplete="email" 
            />

            <textarea
              className={styles.textArea}
              rows="10"
              placeholder="Treść wiadomości"
              value={message}
              onChange={handleMessageChange}
              id="Form" // Dodaj id dla tego pola
              name="formularz" 
              autoComplete="formularz" 
            ></textarea>
            <button className={styles.submitButton} type="submit">
              Prześlij
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from 'react';
import styles from './Reviews.module.css';
import opinieData from '../natalia_comments.json';
import ScrollReveal from 'scrollreveal';

const Opinions = () => {
  const [userNames, setUserNames] = useState([]);
  const [userCities, setUserCities] = useState([]);
  const [commentDates, setCommentDates] = useState([]);
  const [comments, setComments] = useState([]);
  const [weddingDates, setWeddingDates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  
  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal(`.${styles.opinieList}`, {
      duration: 2700,
      scale: 0.8,
      distance: '500px',
      origin: 'bottom',
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });
  }, []);

  useEffect(() => {
    setUserNames(opinieData.user_names);
    setUserCities(opinieData.user_cities);
    setCommentDates(opinieData.comment_dates);
    setComments(opinieData.comments);
    setWeddingDates(opinieData.wedding_dates);
  }, []);

  const opiniePerPage = 3;
  const totalPages = Math.ceil(userNames.length / opiniePerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const renderOpinie = () => {
    if (!Array.isArray(userNames)) {
      return null; // lub inny odpowiedni komunikat błędu
    }

    const startIdx = currentPage * opiniePerPage;
    const endIdx = startIdx + opiniePerPage;

    const opinieRows = [];

    for (let i = startIdx; i < endIdx; i++) {
      if (i >= userNames.length) {
        break;
      }

      opinieRows.push(
        
        <div className={styles.opinia} key={i}>
          <div className={styles.opiniaSection}>
            <div>
              <strong>Autor:</strong> {userNames[i]}
            </div>
            <div>
              <strong>Miejscowość:</strong> {userCities[i]}
            </div>
            <div>
              <strong>Data dodania komentarza:</strong> {commentDates[i]}
            </div>
            <div>
              <strong>Opinia:</strong> {comments[i]}
            </div>
            <div>
              <strong>Data ślubu:</strong> {weddingDates[i]}
            </div>
          </div>
        </div>
      );
    }

    return opinieRows;
  };

  return (
    
    <div id='opinie' className={styles.opinieContainer}>
        <h1>Opinie</h1>
      <div className={styles.opinieList}>{renderOpinie()}</div>
      <div className={styles.pagination}>
        <button onClick={handlePrevPage}>&lt; Poprzednie</button>
        <span>
          Strona {currentPage + 1} z {totalPages}
        </span>
        <button onClick={handleNextPage}>Następne &gt;</button>
      </div>
    </div>
  );
};

export default Opinions;

import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import styles from './Hero.module.css';
import heroImage from './heroImage1.webp';
import ScrollReveal from 'scrollreveal';

const Hero = () => {

  const handleOfferLinkClick = () => {
    // Przewiń do górnej krawędzi strony
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal(`.${styles.subtitle}`, {
      duration: 3000,
      scale: 1,
      distance: '300px',
      origin: 'left',
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });
  }, []);
  return (
    <div id="/" className={styles['hero-container']}>
      <div className={styles['hero-content']}>
        <h1 className={styles.title}>Natalia <br></br>Watkowska</h1>
        <p className={styles.subtitle}>Zanurz się w pięknie muzyki</p>
      </div>
      <div className={styles['hero-image']}>
        <img src={heroImage} alt="Natalia Watkowska" className={styles.heroImage} />
        <div className={styles['image-button']}>
        <Link to="oferta" onClick={() => { handleOfferLinkClick()}}>
          <button className={styles.heroButton}>OFERTA</button>
          </Link>
          </div>
      </div>
    </div>
  );
};

export default Hero;

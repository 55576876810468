// AboutMe.jsx
import React, { useEffect } from "react";
import styles from "./AboutMe.module.css";
import meImage from "./meImage.png";
import ScrollReveal from "scrollreveal";

const AboutMe = () => {
  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal(`.${styles.textContent}`, {
      duration: 3000,
      scale: 0.6,
      distance: "600px",
      origin: "left",
      reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
    });
  }, []);

  return (
    <div id="about" className={styles["about-me-container"]}>
      <div className={styles["about-me-content"]}>
        <div className={styles.textContent}>
          {/* <h1>O mnie</h1> */}
          <span>
            <b>Cześć!</b>
          </span>
          <p>
            {" "}
            <br></br>Miło mi Cię powitać w moim miejscu w sieci.
            <br></br>
            Nazywam się Natalia Watkowska, pochodzę z Grudziądza, a od dłuższego
            czasu mieszkam pod Warszawą. To tu postanowiłam, że w życiu będę
            robić to, co kocham. Z wykształcenia jestem nauczycielem wychowania
            przedszkolnego, a z zamiłowania wokalistką. Od około 10 lat zajmuję
            się oprawą muzyczną ślubów i umuzykalniam dzieci. Jestem również
            certyfikowanym instruktorem zajęć gordonowskich i dbam o to, żeby
            już od najmłodszych lat muzyka była obecna w życiu młodego
            człowieka. Nieustannie szkolę swoje umiejętności wokalno-muzyczne,
            biorąc udział w różnych kursach, szkoleniach i warsztatach.
            Aktualnie jestem w trakcie studiów podyplomowych na kierunku
            “Rytmika i muzykoterapia w edukacji wczesnoszkolnej i
            przedszkolnej”. Śpiewam również w chórze rozrywkowym Souladies,
            gdzie wspólnie z innymi dziewczynami mogę odkrywać świat muzyki
            soul, pop oraz gospel i występować na warszawskich scenach. Poza
            muzyką uwielbiam czas spędzany z moim mężem i córeczką, lubię też
            czytać książki, piec ciasta i pływać.
          </p>
        </div>
        <div className={styles["image-content"]}>
          <img src={meImage} alt="Moje zdjęcie" className={styles.Myimage} />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import styles from './Navigation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import ScrollReveal from 'scrollreveal';



const Navigation = () => {
  useEffect(() => {
    const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;
    const sr = ScrollReveal();

    if (!isMobile) {
      sr.reveal(`.${styles.navItem}`, {
        duration: 2000,
        scale: 0.7,
        distance: '100px',
        origin: 'bottom',
        reset: false, // Dodaj reset, aby animacje zawsze się uruchamiały przy każdym przewinięciu
      });
    }
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', offset: -50 });
      closeMenu();
    }
  };

  const handleFAQClick = (elementId) => {
    // Jeśli jesteś na stronie FAQ, przewiń do sekcji "reviews"
    if (location.pathname === '/FAQ' || location.pathname === '/oferta') {
      navigate('/');
      setTimeout(() => {
        scrollToElement(elementId);
      }, 50); // Przewiń do "reviews" po opóźnieniu
    }
  }

  const handleOfferLinkClick = () => {
    // Przewiń do górnej krawędzi strony
    window.scrollTo({ top: 0 });
  };

  return (
    <nav className={styles['nav-bar']}>
      <button className={`${styles.hamburger} ${menuOpen ? styles['hamburger--active'] : ''}`} aria-label="Menu" onClick={toggleMenu}>
        <span className={styles.hamburger__container} tabIndex="-1">
          <span className={styles.hamburger__bars}></span>
        </span>
      </button>
      <ul className={menuOpen ? styles['nav-list-open'] : styles['nav-list']}>
        <li className={styles.navItem}>
          <ScrollLink to="/" smooth={true} offset={-70} duration={500} className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('/'); closeMenu(); }}>
            HOME
          </ScrollLink>
        </li>
        <li className={styles.navItem}>
          <ScrollLink to="about" smooth={true} offset={-70} duration={500} className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('about'); closeMenu(); }}>
            O MNIE
          </ScrollLink>
        </li>
        <li className={styles.navItem}>
          <RouterLink to="/oferta" className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('services'); closeMenu(); }}>
            OFERTA
          </RouterLink>
        </li>
        <li className={styles.navItem}>
          <ScrollLink to="recordings" smooth={true} offset={-70} duration={500} className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('recordings'); closeMenu(); }}>
            NAGRANIA
          </ScrollLink>
        </li>
        <li className={styles.navItem}>
          <ScrollLink to="opinie" smooth={true} offset={-70} duration={500} className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('opinie'); closeMenu(); }}>
            OPINIE
          </ScrollLink>
        </li>
        <li className={styles.navItem}>
          <RouterLink to="FAQ" className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('reviews'); closeMenu(); }}>
            FAQ
          </RouterLink>
        </li>
        <li className={styles.navItem}>
          <ScrollLink to="contact" smooth={true} offset={-70} duration={500} className={styles['nav-link']} onClick={() => { handleOfferLinkClick(); handleFAQClick('contact'); closeMenu(); }}>
            KONTAKT
          </ScrollLink>
        </li>
      </ul>
      <div className={styles.iconContainer}>
        <RouterLink to="https://www.facebook.com/nataliaa.watkowska" target="_blank" rel="noopener noreferrer" className={styles.iconLink}>
          <FontAwesomeIcon icon={faFacebookF} size="2x" color="#6f3f2d" />
        </RouterLink>
        <RouterLink to="https://www.instagram.com/natalia_watkowska/?fbclid=IwAR1OF4Njgjrhnok-18FK_wpiwZyBwVBaoKjpECz_K8I1yMWmhbdVFzVHwaI" target="_blank" rel="noopener noreferrer" className={styles.iconLink}>
          <FontAwesomeIcon icon={faInstagram} size="2x" color="#6f3f2d" />
        </RouterLink>
        <RouterLink to="https://www.youtube.com/channel/UCweZaNh6Tx8p7IEfNKxPTcA" target="_blank" rel="noopener noreferrer" className={styles.iconLink}>
          <FontAwesomeIcon icon={faYoutube} size="2x" color="#6f3f2d" />
        </RouterLink>
      </div>
    </nav>
  );
};

export default Navigation;
